import React from "react"
import classnames from "classnames/bind"
// Style
import viewStyle from "../style/components/view-more.module.scss"

const ViewMore = props => {
  const text = props.text ? props.text : "view more"

  return pug`
    div#viewMore.flex.justify-center.items-center(className=classnames(viewStyle.wrapper,props.className))
      span.uppercase.text-theme-100=text
  `
}

export default ViewMore
