import React, { useRef, useState, useEffect } from "react"
import Link from "gatsby-plugin-transition-link"
import classnames from "classnames/bind"
import { motion } from "framer-motion"
import Img from "gatsby-image"
import TransitionLink, {
  TransitionPortal,
  TransitionState,
} from "gatsby-plugin-transition-link"

// Component
import { ArrowRight } from "phosphor-react"
import {
  ListTLink,
  animateObjects,
  newContent,
  animateHero,
} from "../layouts/wrapper"
import ViewMore from "./view-more"

// Style
import blockStyle from "../style/components/block.module.scss"
import viewStyle from "../style/components/view-more.module.scss"

const transition = {
  ease: [0.65, 0.05, 0.36, 1],
  duration: 0.7,
}

const backdropTransition = {
  ease: [0.77, 0, 0.18, 1],
  duration: 1,
}

// 因為pug無法正確寫出以下這一個component，所以拉出來用JS寫在這邊
const PageLink = props => (
  <ListTLink
    to={props.to}
    exit={{
      length: 1.5,
    }}
    entry={{
      length: 1.5,
      appearAfter: 1.7,
      // trigger: ({ entry, node }) => newContent(node),
    }}
    trigger={async pages => {
      // wait until we have access to both pages
      const exit = await pages.exit
      const entry = await pages.entry
      // pass exit and entry element to function
      animateHero(exit, entry, props.fromEl, props.toEl, props.image_url)
      await entry.visible
    }}
  >
    {props.children}
  </ListTLink>
)
const BlockContent = props => {
  return pug`
    div.pt(className=blockStyle.blockInfo)
      div.col-12.col-md-6(className=blockStyle.blockInfo_content)
        h3.capitalize=props.title
      div.col-12.col-md-6.mt-1(className=blockStyle.blockInfo_desc)
        p.txt-caption.mb-2=props.cate
        p.text-base.text-gray-200=props.description
  `
}

const FullSizeBlock = React.forwardRef((props, ref) => {
  const blockref = useRef(null)

  // ViewMore Hover
  const blockImageRef = useRef(null)
  const [isHover, setIsHover] = useState(false)

  useEffect(() => {
    const viewMore = blockImageRef.current.querySelector("#viewMore")
    isHover == true
      ? viewMore.classList.add(viewStyle.hover)
      : viewMore.classList.remove(viewStyle.hover)
  }, [isHover])

  return pug`
    div.col-12.px-0(className=blockStyle.blockFullWrapper ref=ref)
      PageLink(
        to=props.link
        fromEl=blockref
        toEl="#heroImage"
      )
        div(className=blockStyle.blockImage ref=blockImageRef onMouseEnter=() => setIsHover(true) onMouseLeave=() => setIsHover(false))
          ViewMore.text-sm.tracking-widest
          div#blockImageWrapper.col-12.px-0.flex.justify-center.items-center(className=blockStyle.blockImage_wrapper ref=blockref)
            Img(fluid=props.image_url alt=props.image_alt style={ height:'100%', width:'100%' })

        BlockContent(title=props.title cate=props.cate description=props.description)
  `
})

const ColumSizeBlock = React.forwardRef((props, ref) => {
  const blockref = useRef(null)

  // ViewMore Hover
  const blockImageRef = useRef(null)
  const [isHover, setIsHover] = useState(false)

  useEffect(() => {
    const viewMore = blockImageRef.current.querySelector("#viewMore")
    isHover == true
      ? viewMore.classList.add(viewStyle.hover)
      : viewMore.classList.remove(viewStyle.hover)
  }, [isHover])

  return pug`
    div.col-12.col-md-6(className=classnames(blockStyle.blockFullWrapper,blockStyle.column) ref=ref)
      PageLink(
        to=props.link
        fromEl=blockref
        toEl="#heroImage"
      )
        div(className=blockStyle.blockImage ref=blockImageRef onMouseEnter=() => setIsHover(true) onMouseLeave=() => setIsHover(false))
          ViewMore.text-sm.tracking-widest
          div.col-12.px-0.flex.justify-center.items-center(className=classnames(blockStyle.blockImage_wrapper, blockStyle.blockImage_wrapper_column) ref=blockref)
            Img(fluid=props.image_url alt=props.image_alt style={ height:'100%', width:'100%' })

        div(className=blockStyle.blockInfo)
          div.col-12.mb-4(className=blockStyle.blockInfo_content)
            h3.capitalize=props.title
          div.col-12(className=blockStyle.blockInfo_desc)
            p.txt-caption.mb-2=props.cate
            p.text-base.text-gray-200=props.description

  `
})

const FullSizeBlockNext = React.forwardRef((props, ref) => {
  const blockref = useRef(null)

  // ViewMore Hover
  const blockImageRef = useRef(null)
  const [isHover, setIsHover] = useState(false)

  useEffect(() => {
    const viewMore = blockImageRef.current.querySelector("#viewMore")
    isHover == true
      ? viewMore.classList.add(viewStyle.hover)
      : viewMore.classList.remove(viewStyle.hover)
  }, [isHover])

  return pug`
    div.col-12.px-0.mb-0(className=blockStyle.blockFullWrapper ref=ref)
      PageLink(
        to=props.link
        fromEl=blockref
        toEl="#heroImage"
      )
        div(className=blockStyle.blockImage ref=blockImageRef onMouseEnter=() => setIsHover(true) onMouseLeave=() => setIsHover(false))
          ViewMore.text-sm.tracking-widest
          div#blockImageWrapper.col-12.px-0.flex.justify-center.items-center(className=blockStyle.blockImage_wrapper ref=blockref)
            Img(fluid=props.image_url alt=props.image_alt style={ height:'100%', width:'100%' })

        div.pt(className=blockStyle.blockInfo)
          div.col-12.col-md-6(className=blockStyle.blockInfo_content)
            h3.text-theme-100.capitalize=props.title
          div.col-12.col-md-6.mt-1(className=blockStyle.blockInfo_desc)
            p.text-theme-300.txt-caption.mb-2=props.cate
            p.text-base.text-theme-200=props.description
  `
})

const ColumSizeComingSoonBlock = React.forwardRef((props, ref) => {
  const blockref = useRef(null)

  // ViewMore Hover
  const blockImageRef = useRef(null)
  const [isHover, setIsHover] = useState(false)

  useEffect(() => {
    const viewMore = blockImageRef.current.querySelector("#viewMore")
    isHover == true
      ? viewMore.classList.add(viewStyle.hover)
      : viewMore.classList.remove(viewStyle.hover)
  }, [isHover])

  return pug`
    div.col-12.col-md-6(className=classnames(blockStyle.blockFullWrapper,blockStyle.column) ref=ref)
      div
        div(className=blockStyle.blockImage ref=blockImageRef onMouseEnter=() => setIsHover(true) onMouseLeave=() => setIsHover(false))
          ViewMore.text-sm.tracking-widest(text="Coming Soon")
          div.col-12.px-0.flex.justify-center.items-center(className=classnames(blockStyle.blockImage_wrapper, blockStyle.blockImage_wrapper_column) ref=blockref)
            Img(fluid=props.image_url alt=props.image_alt style={ height:'100%', width:'100%' })

        div(className=blockStyle.blockInfo)
          div.col-12.mb-4(className=blockStyle.blockInfo_content)
            h3.capitalize=props.title
          div.col-12(className=blockStyle.blockInfo_desc)
            p.txt-caption.mb-2=props.cate
            p.text-base.text-gray-200=props.description

  `
})

export {
  FullSizeBlock,
  ColumSizeBlock,
  FullSizeBlockNext,
  ColumSizeComingSoonBlock,
}
